<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animate__animated animate__tada"
    leave-active-class="animate__animated animate__bounceOutRight"
  >
    <v-chip
      x-large
      pill
      :color="item.color"
      class="white--text participant text-h5 font-weight-black ma-2 animate__animated animate__bounce animate__infinite"
    >
      <v-avatar v-if="avatar" color="accent1" class="mr-2"></v-avatar>
      <span :class="{ color: item.color }">{{ item.name.toLowerCase() }}</span>
    </v-chip>
  </transition>
</template>

<script>
export default {
  name: "PresenterWaitParticipantChip",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      avatar: false,
    }
  },
}
</script>
<style lang="scss" scoped>
.participant {
  border-width: 0.2rem;
  transition: all 1s ease;
  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      animation-delay: 2s * $i;
      -webkit-animation-delay: 2s * $i;
      -moz-animation-delay: 2s * $i;
    }
  }
}
</style>
