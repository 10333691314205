var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.gameSession
    ? _c(
        "v-container",
        {
          staticClass: "wrapper ma-0 pa-0",
          attrs: { "fill-height": "", fluid: "" }
        },
        [
          !_vm.gameSession.started
            ? _c("PresenterWaitCard", { attrs: { gamepin: _vm.gamepin } })
            : _vm._e(),
          _vm.gameSession.started && !_vm.gameSession.end
            ? [
                _c(
                  "transition",
                  {
                    attrs: {
                      "enter-active-class":
                        "animate__animated animate__bounceIn",
                      "leave-active-class":
                        "animate__animated animate__bounceOut",
                      mode: "out-in"
                    }
                  },
                  [
                    _c("PresenterQuestion", {
                      attrs: { devmode: _vm.devmode, time: _vm.time }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.gameSession.end ? _c("final-score") : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }