var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "background waitcard pa-sm-8",
      attrs: { "fill-height": "" }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "secondary--text",
          attrs: { align: "center", justify: "space-between" }
        },
        [
          _c("v-col", { attrs: { cols: "auto", align: "center" } }, [
            _c(
              "span",
              {
                staticClass:
                  "font-weight-bold accent--text title text-uppercase"
              },
              [_vm._v("Join At")]
            ),
            _c("p", { staticClass: "font-weight-bold text-h3" }, [
              _vm._v("qizto.com/join")
            ])
          ]),
          _c("v-col", { attrs: { cols: "auto", align: "center" } }, [
            _c(
              "span",
              {
                staticClass:
                  "font-weight-bold accent--text title text-uppercase"
              },
              [_vm._v("Pin")]
            ),
            _c(
              "p",
              {
                staticClass:
                  "font-weight-bold text-h3 animate__animated animate__pulse animate__infinite"
              },
              [_vm._v(" " + _vm._s(_vm.gamepin) + " ")]
            )
          ]),
          _c("v-col", { attrs: { cols: "auto", align: "center" } }, [
            _c(
              "span",
              {
                staticClass:
                  "font-weight-bold accent--text title text-uppercase"
              },
              [_vm._v("Online")]
            ),
            _c("p", { staticClass: "font-weight-bold text-h3 mr-4" }, [
              _vm._v(_vm._s(_vm.participantcount))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center", dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-img", {
                attrs: { src: _vm.qrcode, height: "100%", width: "100%" }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex pa-2 flex-wrap",
                  attrs: { color: "transparent", elevation: "0" }
                },
                _vm._l(_vm.participantList, function(participant, index) {
                  return _c("PresenterWaitParticipantChip", {
                    key: index,
                    attrs: { item: participant }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pa-4", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-space-around",
              attrs: { cols: "12" }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", large: "", color: "error" },
                  on: {
                    click: function($event) {
                      return _vm.deleteSession()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "times-circle", size: "lg" }
                  }),
                  _vm._v(" Delete Session")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { large: "", outlined: "", color: "success" },
                  on: {
                    "~click": function($event) {
                      return _vm.start()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "play-circle", size: "lg" }
                  }),
                  _vm._v(" Start Quiz")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", large: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "arrow-circle-left", size: "lg" }
                  }),
                  _vm._v(" Back Home")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("confirm-dialog", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }