var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c(
        "v-row",
        {
          staticClass:
            "d-flex timer-section justify-sm-space-between justify-center align-self-start pa-2",
          attrs: { dense: "" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center align-center",
              attrs: { cols: 12 }
            },
            [
              _c(
                "v-progress-linear",
                {
                  staticClass: "rounded-lg",
                  attrs: {
                    dark: "",
                    elevation: "0",
                    color: "accent2",
                    "background-color": "background",
                    height: _vm.$vuetify.breakpoint.smAndUp ? 40 : 20,
                    value: _vm.progressValue
                  }
                },
                [
                  _vm.seconds > 0
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold secondary--text timer-text"
                        },
                        [_vm._v(_vm._s(_vm.seconds))]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }