var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c(
        "v-row",
        {
          staticClass: "d-flex answer-section justify-center align-self-end",
          attrs: { dense: _vm.$vuetify.breakpoint.xs }
        },
        [
          _vm._t("power-ups"),
          _vm._l(_vm.question.answers, function(answer, answerindex) {
            return _c(
              "v-col",
              {
                key: answerindex,
                staticClass: "d-flex justify-center align-stretch ma-0",
                attrs: { cols: "12", sm: "6" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold answercontent flex-grow-1 d-flex justify-start pa-6 pa-sm-8 rounded-lg",
                    attrs: {
                      "x-large": "",
                      dark: "",
                      color: _vm.answercolor[answerindex]
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "mr-4 font-weight-bold",
                        class:
                          _vm.answercolor[answerindex] +
                          "--text text--lighten-4"
                      },
                      [_vm._v(_vm._s(answerindex + 1))]
                    ),
                    _vm._v(_vm._s(answer))
                  ]
                )
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }