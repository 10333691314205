<template>
  <v-row class="d-flex answer-section justify-center align-stretch" dense v-if="question">
    <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12" class="d-flex flex-column justify-center align-center">
      <lottie-player
        v-if="this.question.answertype !== 'P'"
        src="https://assets1.lottiefiles.com/packages/lf20_vhhoyvvc.json"
        background="transparent"
        speed="0.5"
        style="width: 150px; height: 150px"
        autoplay
      ></lottie-player>
      <lottie-player
        v-if="this.question.answertype === 'P'"
        src="https://assets10.lottiefiles.com/packages/lf20_X4UwkZ.json"
        background="transparent"
        speed="0.5"
        style="width: 150px; height: 150px"
        autoplay
      ></lottie-player>
      <p class="overline text-center font-weight-bold secondary--text" v-if="question.answertype !== 'P'">
        Correct Answer
      </p>
      <p class="overline text-center font-weight-black secondary--text" v-else>This was a Poll</p>
      <p class="text-h3 text-center font-weight-black secondary--text" v-if="question.answertype !== 'P'">
        {{ correctAnswers }}
      </p>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12">
      <slot name="answer-stats"></slot>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "PresenterRightAnswer",
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
    correctAnswers() {
      let tempAnswers = []
      if (this.question.answertype !== "P") {
        this.question.answers
          .filter((answer, index) => this.question.correctanswers[index])
          .forEach((answer) => tempAnswers.push(answer))
      } else {
        tempAnswers.push("This was a Poll, there is NO right answer")
      }
      return tempAnswers.toString()
    },
  },
}
</script>

<style lang="scss" scoped></style>
