var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name: "custom-classes-transition",
        "enter-active-class": "animate__animated animate__tada",
        "leave-active-class": "animate__animated animate__bounceOutRight"
      }
    },
    [
      _c(
        "v-chip",
        {
          staticClass:
            "white--text participant text-h5 font-weight-black ma-2 animate__animated animate__bounce animate__infinite",
          attrs: { "x-large": "", pill: "", color: _vm.item.color }
        },
        [
          _vm.avatar
            ? _c("v-avatar", {
                staticClass: "mr-2",
                attrs: { color: "accent1" }
              })
            : _vm._e(),
          _c("span", { class: { color: _vm.item.color } }, [
            _vm._v(_vm._s(_vm.item.name.toLowerCase()))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }