<template>
  <v-row class="d-flex justify-center align-self-end">
    <v-col cols="auto">
      <v-btn outlined large color="secondary" @click="endquiz">
        <font-awesome-icon icon="stop-circle" pull="left"></font-awesome-icon>
        End Quiz</v-btn
      >
    </v-col>
    <v-col cols="auto">
      <v-btn outlined color="secondary" large @click="nextquestion" :disabled="!gameSession.showleader && !devmode">
        <font-awesome-icon icon="arrow-right" pull="left"></font-awesome-icon>

        Next Question</v-btn
      >
    </v-col>
    <v-col cols="auto" v-if="devmode">
      <v-btn outlined color="secondary" large @click="showanswer"> Show Answer</v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn outlined large color="secondary" :disabled="!gameSession.showanswer && !devmode" @click="showleaderboard">
        <font-awesome-icon icon="star-half-alt" pull="left"></font-awesome-icon>
        Leaderboard</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "PresenterActionBar",
  props: {
    devmode: {
      default: () => false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      gameSession: (state) => state.game.gameSession,
    }),
  },
  methods: {
    showanswer() {
      this.$eventBus.$emit("show-answer")
    },
    endquiz() {
      this.$eventBus.$emit("end-quiz")
    },
    nextquestion() {
      this.$eventBus.$emit("next-question")
    },
    showleaderboard() {
      this.$eventBus.$emit("show-leaderboard")
    },
  },
}
</script>

<style lang="scss" scoped></style>
