<template>
  <v-container class="background waitcard pa-sm-8" fill-height>
    <v-row align="center" justify="space-between" class="secondary--text">
      <v-col cols="auto" align="center">
        <span class="font-weight-bold accent--text title text-uppercase">Join At</span>
        <p class="font-weight-bold text-h3">qizto.com/join</p>
      </v-col>
      <v-col cols="auto" align="center">
        <span class="font-weight-bold accent--text title text-uppercase">Pin</span>
        <p class="font-weight-bold text-h3 animate__animated animate__pulse animate__infinite">
          {{ gamepin }}
        </p>
      </v-col>
      <v-col cols="auto" align="center">
        <span class="font-weight-bold accent--text title text-uppercase">Online</span>
        <p class="font-weight-bold text-h3 mr-4">{{ participantcount }}</p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" dense>
      <v-col cols="4">
        <v-img :src="qrcode" height="100%" width="100%"> </v-img>
      </v-col>
      <v-col cols="8">
        <v-card class="d-flex pa-2 flex-wrap" color="transparent" elevation="0">
          <PresenterWaitParticipantChip
            :item="participant"
            v-for="(participant, index) in participantList"
            :key="index"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="pa-4">
      <v-col cols="12" class="d-flex justify-space-around">
        <v-btn @click="deleteSession()" outlined large color="error">
          <font-awesome-icon icon="times-circle" size="lg" class="mr-2"></font-awesome-icon>
          Delete Session</v-btn
        >
        <v-btn large @click.once="start()" outlined color="success">
          <font-awesome-icon icon="play-circle" size="lg" class="mr-2"></font-awesome-icon>
          Start Quiz</v-btn
        >
        <v-btn @click="goBack()" outlined large color="secondary">
          <font-awesome-icon icon="arrow-circle-left" size="lg" class="mr-2"></font-awesome-icon>
          Back Home</v-btn
        >
      </v-col>
    </v-row>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-container>
</template>

<script>
import { Timestamp } from "firebase/firestore"
import { mapState, mapMutations } from "vuex"
import ConfirmDialog from "@/components/common/ConfirmDialog.vue"
import PresenterWaitParticipantChip from "@/components/game/presenter/PresenterWaitParticipantChip.vue"
import { updateGameSession, deleteActiveSession } from "@/api/GameApi"
import { deleteSession } from "@/api/QuizApi"

export default {
  name: "PresenterWaitCard",
  props: {
    gamepin: {
      default: () => null,
      type: String,
    },
  },
  data() {
    return {
      qrcode: require("@/assets/gameplay/qizto_qr.svg"),
    }
  },
  components: {
    ConfirmDialog,
    PresenterWaitParticipantChip,
  },
  computed: {
    ...mapState({
      gameSession: (state) => state.game.gameSession,
      participantList: (state) => state.game.participantList,
    }),
    participantcount() {
      return this.participantList?.length
    },
  },
  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR", clearGameData: "CLEAR_GAME_DATA" }),
    async start() {
      const futuretime = Timestamp.now().seconds + this.gameSession.questions[0].timer || this.gameSession.timer
      try {
        await updateGameSession(this.gameSession, { index: 0, futuretime: futuretime, started: true })
        this.snackmsg = "Let's Roll!"
        this.showAlert(this.snackmsg)
      } catch (err) {
        this.showAlert(err.message)
        this.$router.push("/dashboard")
      }
    },
    async deleteSession() {
      if (
        await this.$refs.confirm.open(
          "Delete",
          "Players won't be able to join with this Game PIN anymore and this session will be deleted. Are you sure ?",
          {
            color: "red",
          }
        )
      ) {
        try {
          await deleteSession(this.gameSession.quizid, this.gameSession.sessionid)
          await deleteActiveSession(this.gameSession)
        } catch (err) {
          this.showAlert(err.message)
        } finally {
          this.$router.push("/dashboard", () => {})
        }
      }
    },

    async goBack() {
      if (
        await this.$refs.confirm.open(
          "Delete",
          "Players won't be able to join with this Game PIN anymore. Session will still remain active and you can view the results from Reports. Are you sure ?",
          {
            color: "red",
          }
        )
      ) {
        this.clearGameData()
        this.$router.push({ name: "Quizzes" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.waitcard {
  min-height: 100vh;
}
</style>
