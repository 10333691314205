<template>
  <v-container
    class="background final-score d-flexjustify-start align-start ma-0 pa-2 pa-sm-4 pa-xl-8 mx-auto"
    :fluid="$vuetify.breakpoint.xs"
    fill-height
  >
    <v-row
      color="transparent"
      class="animate__jackInTheBox bordercard animate__animated animate__jackInTheBox justify-center align-start d-flex"
      elevation="0"
      width="100%"
    >
      <v-col cols="12">
        <v-img height="200" contain class="gif-image" src="@/assets/gameplay/trophy.gif"></v-img>
      </v-col>
      <PresenterLeaderBoard :maxleaders="maxleaders" :showotherstats="showotherstats"></PresenterLeaderBoard>
    </v-row>
    <v-row class="d-flex justify-center align-self-end">
      <v-btn color="secondary" @click="back">
        <font-awesome-icon icon="fas-fa-list" class="mr-6" pull="left"></font-awesome-icon>
        Back to Quizzes</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex"
import PresenterLeaderBoard from "@/components/game/presenter/PresenterLeaderBoard"
export default {
  name: "final-score",
  components: {
    PresenterLeaderBoard,
  },
  data() {
    return {
      snacktext: null,
      maxleaders: 10,
      showotherstats: false,
    }
  },
  // computed: {
  //   ...mapGetters({ leaders: "getLeaders" }),
  // },

  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR", clearGameData: "CLEAR_GAME_DATA" }),

    back() {
      this.snacktext = "Game Ended! successfully"
      this.clearGameData()
      this.$router.push("/dashboard")
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  border: 1px solid var(--v-primary-base) !important;
}
.final-score {
  min-height: 100vh;
  background-image: url("~@/assets/background.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
}
</style>
