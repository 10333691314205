var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c(
        "v-row",
        {
          staticClass: "d-flex answer-section justify-center align-stretch",
          attrs: { dense: "" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column justify-center align-center",
              attrs: { cols: _vm.$vuetify.breakpoint.mdAndUp ? 4 : 12 }
            },
            [
              this.question.answertype !== "P"
                ? _c("lottie-player", {
                    staticStyle: { width: "150px", height: "150px" },
                    attrs: {
                      src:
                        "https://assets1.lottiefiles.com/packages/lf20_vhhoyvvc.json",
                      background: "transparent",
                      speed: "0.5",
                      autoplay: ""
                    }
                  })
                : _vm._e(),
              this.question.answertype === "P"
                ? _c("lottie-player", {
                    staticStyle: { width: "150px", height: "150px" },
                    attrs: {
                      src:
                        "https://assets10.lottiefiles.com/packages/lf20_X4UwkZ.json",
                      background: "transparent",
                      speed: "0.5",
                      autoplay: ""
                    }
                  })
                : _vm._e(),
              _vm.question.answertype !== "P"
                ? _c(
                    "p",
                    {
                      staticClass:
                        "overline text-center font-weight-bold secondary--text"
                    },
                    [_vm._v(" Correct Answer ")]
                  )
                : _c(
                    "p",
                    {
                      staticClass:
                        "overline text-center font-weight-black secondary--text"
                    },
                    [_vm._v("This was a Poll")]
                  ),
              _vm.question.answertype !== "P"
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-h3 text-center font-weight-black secondary--text"
                    },
                    [_vm._v(" " + _vm._s(_vm.correctAnswers) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mdAndUp ? 8 : 12 } },
            [_vm._t("answer-stats")],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }