var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass:
        "background final-score d-flexjustify-start align-start ma-0 pa-2 pa-sm-4 pa-xl-8 mx-auto",
      attrs: { fluid: _vm.$vuetify.breakpoint.xs, "fill-height": "" }
    },
    [
      _c(
        "v-row",
        {
          staticClass:
            "animate__jackInTheBox bordercard animate__animated animate__jackInTheBox justify-center align-start d-flex",
          attrs: { color: "transparent", elevation: "0", width: "100%" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-img", {
                staticClass: "gif-image",
                attrs: {
                  height: "200",
                  contain: "",
                  src: require("@/assets/gameplay/trophy.gif")
                }
              })
            ],
            1
          ),
          _c("PresenterLeaderBoard", {
            attrs: {
              maxleaders: _vm.maxleaders,
              showotherstats: _vm.showotherstats
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "d-flex justify-center align-self-end" },
        [
          _c(
            "v-btn",
            { attrs: { color: "secondary" }, on: { click: _vm.back } },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-6",
                attrs: { icon: "fas-fa-list", pull: "left" }
              }),
              _vm._v(" Back to Quizzes")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }