<template>
  <v-row
    class="d-flex timer-section justify-sm-space-between justify-center align-self-start pa-2"
    dense
    v-if="question"
  >
    <v-col :cols="12" class="d-flex justify-center align-center">
      <v-progress-linear
        dark
        elevation="0"
        color="accent2"
        background-color="background"
        :height="$vuetify.breakpoint.smAndUp ? 40 : 20"
        :value="progressValue"
        class="rounded-lg"
      >
        <span class="font-weight-bold secondary--text timer-text" v-if="seconds > 0">{{ seconds }}</span>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex"
export default {
  name: "GameTimer",
  props: {
    seconds: {
      default: () => 0,
      type: Number,
    },
  },
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
    ...mapState({
      participantSession: (state) => state.game.participantSession,
    }),
    progressValue() {
      return this.participantSession.showanswer ? (this.seconds / 10) * 100 : (this.seconds / this.question.timer) * 100
    },
    availablePowerUps() {
      return this.gameSession.maxlifelines - this.participantSession.powerupcount
    },
  },
}
</script>

<style lang="scss" scoped>
.timer-section {
  .timer-text {
    font-size: clamp(1.5rem, 1.5vw, 3vw);
  }
}
</style>
