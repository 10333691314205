<template>
  <v-container fill-height v-if="gameSession" fluid class="wrapper ma-0 pa-0">
    <PresenterWaitCard v-if="!gameSession.started" :gamepin="gamepin"> </PresenterWaitCard>
    <template v-if="gameSession.started && !gameSession.end">
      <transition
        enter-active-class="animate__animated animate__bounceIn"
        leave-active-class="animate__animated animate__bounceOut"
        mode="out-in"
      >
        <PresenterQuestion :devmode="devmode" :time="time"> </PresenterQuestion>
      </transition>
    </template>
    <final-score v-if="gameSession.end"></final-score>
  </v-container>
</template>

<script>
import { Timestamp } from "firebase/firestore"
import { mapMutations, mapState } from "vuex"
import { getGameSession, getParticipantList, updateGameSession, deleteActiveSession } from "@/api/GameApi"
import PresenterWaitCard from "@/components/game/presenter/PresenterWaitCard.vue"
import PresenterQuestion from "@/components/game/presenter/PresenterQuestion.vue"
import FinalScore from "@/components/game/FinalScore.vue"

export default {
  name: "Presenter",
  components: {
    PresenterWaitCard,
    PresenterQuestion,
    FinalScore,
  },
  data() {
    return {
      loaded: false,
      gamepin: null,
      route: [],
      time: null,
      devmode: false,
    }
  },

  mounted() {
    this.$eventBus.$on("show-answer", this.showAnswer)
    this.$eventBus.$on("next-question", this.nextQuestion)
    this.$eventBus.$on("show-leaderboard", this.showLeaderBoard)
    this.$eventBus.$on("end-quiz", this.endquiz)
    this.route = this.$route.params.id.split("-")
    this.gamepin = this.route[2]
    this.fetchSession(this.route)
  },

  beforeDestroy() {
    this.$eventBus.$off("show-answer")
    this.$eventBus.$off("next-question")
    this.$eventBus.$off("show-leaderboard")
    this.$eventBus.$off("end-quiz")
  },

  computed: {
    ...mapState({
      gameSession: (state) => state.game.gameSession,
    }),
  },

  watch: {
    "gameSession.futuretime": {
      immediate: true,
      handler: function (newValue) {
        if (newValue > 0 && !this.devmode) {
          // console.log(newValue)
          console.log("PRESENTER TIMER TRIGGERD")
          this.timer()
        }
      },
    },
  },

  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR", clearGameData: "CLEAR_GAME_DATA" }),

    async fetchSession(res) {
      try {
        const [quizid, sessionid] = res
        await Promise.all([getGameSession(quizid, sessionid), getParticipantList(quizid, sessionid)])
        this.loaded = true
      } catch (err) {
        console.log(err)
        this.showAlert(err.message)
      }
    },

    timer() {
      let timerId = setInterval(() => {
        if (Timestamp.now().seconds <= this.gameSession.futuretime) {
          // console.log(this.time)
          this.time = this.gameSession.futuretime - Timestamp.now().seconds
        } else {
          if (!this.gameSession.showleader && !this.gameSession.showanswer && this.time === 0) {
            this.showAnswer()
          }
          clearInterval(timerId)
        }
      }, 1000)
    },

    async nextQuestion() {
      try {
        const index = this.gameSession.index + 1
        if (index !== this.gameSession.questions.length) {
          const futuretime = Timestamp.now().seconds + this.gameSession.questions[index].timer || this.gameSession.timer
          await updateGameSession(this.gameSession, { index: index, futuretime: futuretime, showleader: false })
        } else {
          this.endquiz()
        }
      } catch (err) {
        this.showAlert(`${this.$store.state.alertmsgs.unexpected}- ${err.message}`)
      }
    },

    async showAnswer() {
      try {
        await updateGameSession(this.gameSession, { showanswer: true })
      } catch (err) {
        this.showAlert(`${this.$store.state.alertmsgs.unexpected} - ${err.message}`)
      }
    },

    async showLeaderBoard() {
      try {
        await updateGameSession(this.gameSession, { showleader: true, showanswer: false })
      } catch (err) {
        this.showAlert(`${this.$store.state.alertmsgs.unexpected}- ${err.message}`)
      }
    },

    async endquiz() {
      try {
        await updateGameSession(this.gameSession, { end: true })
        await deleteActiveSession(this.gameSession)
      } catch (err) {
        this.showAlert(`${this.$store.state.alertmsgs.unexpected}- ${err.message}`)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #fff3e0;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #fff3e0 10px),
    repeating-linear-gradient(#ffdfad55, #ffdfad);
}
</style>
