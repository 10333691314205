import { render, staticRenderFns } from "./Presenter.vue?vue&type=template&id=5ae975de&scoped=true&"
import script from "./Presenter.vue?vue&type=script&lang=js&"
export * from "./Presenter.vue?vue&type=script&lang=js&"
import style0 from "./Presenter.vue?vue&type=style&index=0&id=5ae975de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae975de",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/Users/asif/dev/js/qizto/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ae975de')) {
      api.createRecord('5ae975de', component.options)
    } else {
      api.reload('5ae975de', component.options)
    }
    module.hot.accept("./Presenter.vue?vue&type=template&id=5ae975de&scoped=true&", function () {
      api.rerender('5ae975de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Presenter.vue"
export default component.exports