<template>
  <v-container fluid class="leaderboard justify-start">
    <lottie-player
      class="lottie"
      src="https://assets1.lottiefiles.com/packages/lf20_m2emnrvx.json"
      background="transparent"
      speed="1"
      style="width: 100%; height: 100%"
      autoplay
    ></lottie-player>
    <h3 class="text-h4 text-sm-h3 secondary--text font-weight-bold pa-5 mt-4">LeaderBoard</h3>
    <v-simple-table class="transparent">
      <template v-slot:default>
        <thead>
          <tr class="rounded-lg">
            <th class="secondary--text subtitle1 text-sm-h6 font-weight-bold">Rank</th>
            <th class="secondary--text subtitle1 text-sm-h6 font-weight-bold">Name</th>
            <th class="secondary--text subtitle1 text-sm-h6 font-weight-bold">Score</th>
            <th class="secondary--text subtitle1 text-sm-h6 font-weight-bold">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(leader, index) in leaders.slice(0, maxleaders)"
            :key="index + leader.name"
            class="border rounded-lg"
          >
            <td class="subtitle1 text-sm-h6">{{ index + 1 }}</td>
            <td class="subtitle1 text-sm-h6">{{ leader.name }}</td>
            <td class="subtitle1 text-sm-h6">
              <v-chip
                color="secondary"
                class="subtitle1 text-sm-h5 accent2--text font-weight-black"
                label
                :small="$vuetify.breakpoint.xs"
                >+ {{ leader.details[leader.details.length - 1].score }}</v-chip
              >
            </td>
            <td class="subtitle1 text-sm-h6">{{ leader.totalscore }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="d-flex justify-center mt-8" dense v-if="showotherstats">
      <v-col cols="auto" class="d-flex justify-center" v-if="fastest">
        <v-card-subtitle class="title font-weight-bold text-uppercase secondary--text">Fastest Answer</v-card-subtitle>
        <v-chip class="ma-1 px-6" dark :color="fastest.color" large label>
          <v-avatar left class="white primary--text"> {{ fastest.lastselected.elapsedtime }} </v-avatar>
          {{ fastest.name }}
        </v-chip>
      </v-col>
      <v-col cols="auto" class="d-flex justify-center" v-if="streak"
        ><v-card-subtitle class="title font-weight-bold text-uppercase secondary--text"
          >Unbroken streak</v-card-subtitle
        >
        <v-chip
          dark
          large
          class="ma-1 px-6"
          :color="participant.color"
          label
          v-for="participant in streak"
          :key="participant.participantsessionid"
        >
          <v-avatar left class="primary secondary--text">
            {{ participant.lastselected.streak }}
          </v-avatar>
          {{ participant.name }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "PresenterLeaderBoard",
  props: {
    maxleaders: {
      type: Number,
      default: () => 5,
    },
    showotherstats: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    ...mapGetters({ leaders: "getLeaders" }),
    streak() {
      return this.leaders.filter((leader) => leader.lastselected.streak >= 2)?.length
    },
    fastest() {
      return this.$store.getters.getFastestAnswerer
    },
  },
}
</script>

<style lang="scss" scoped>
.leaderboard {
  position: relative;
}
.lottie {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.border {
  border-bottom: 1px solid var(--v-accent2-base);
}
</style>
