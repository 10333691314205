<template>
  <v-row class="d-flex answer-section justify-center align-self-end" :dense="$vuetify.breakpoint.xs" v-if="question">
    <slot name="power-ups"></slot>
    <v-col
      cols="12"
      sm="6"
      v-for="(answer, answerindex) in question.answers"
      :key="answerindex"
      class="d-flex justify-center align-stretch ma-0"
    >
      <v-btn
        x-large
        dark
        :color="answercolor[answerindex]"
        class="font-weight-bold answercontent flex-grow-1 d-flex justify-start pa-6 pa-sm-8 rounded-lg"
        ><span class="mr-4 font-weight-bold" :class="`${answercolor[answerindex]}--text text--lighten-4`">{{
          answerindex + 1
        }}</span
        >{{ answer }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "ParticipantAnswerList",
  props: {
    time: {
      type: Number,
      default: () => 0,
    },
    answers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      answercolor: ["success", "error", "info", "warning", "secondary", "accent"],
    }
  },
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
  },
}
</script>

<style lang="scss" scoped>
.answer-section {
  .answercontent {
    font-size: clamp(1rem, 2.3vmax, 3rem) !important;
    word-break: normal;
    &:hover {
      border-bottom: 0.8rem solid black !important;
    }
  }
  .clicked {
    border-bottom: 0.8rem solid black !important;
  }
  .success {
    border-bottom: 0.8rem solid var(--v-success-darken2) !important;
  }
  .error {
    border-bottom: 0.8rem solid var(--v-error-darken2) !important;
  }
  .info {
    border-bottom: 0.8rem solid var(--v-info-darken2) !important;
  }
  .warning {
    border-bottom: 0.8rem solid var(--v-warning-darken2) !important;
  }
  .secondary {
    border-bottom: 0.8rem solid var(--v-secondary-darken2) !important;
  }
  .accent {
    border-bottom: 0.8rem solid var(--v-accent-darken2) !important;
  }

  .clickable {
    cursor: pointer;
  }
}
</style>
