var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "leaderboard justify-start", attrs: { fluid: "" } },
    [
      _c("lottie-player", {
        staticClass: "lottie",
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          src: "https://assets1.lottiefiles.com/packages/lf20_m2emnrvx.json",
          background: "transparent",
          speed: "1",
          autoplay: ""
        }
      }),
      _c(
        "h3",
        {
          staticClass:
            "text-h4 text-sm-h3 secondary--text font-weight-bold pa-5 mt-4"
        },
        [_vm._v("LeaderBoard")]
      ),
      _c("v-simple-table", {
        staticClass: "transparent",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", { staticClass: "rounded-lg" }, [
                    _c(
                      "th",
                      {
                        staticClass:
                          "secondary--text subtitle1 text-sm-h6 font-weight-bold"
                      },
                      [_vm._v("Rank")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass:
                          "secondary--text subtitle1 text-sm-h6 font-weight-bold"
                      },
                      [_vm._v("Name")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass:
                          "secondary--text subtitle1 text-sm-h6 font-weight-bold"
                      },
                      [_vm._v("Score")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass:
                          "secondary--text subtitle1 text-sm-h6 font-weight-bold"
                      },
                      [_vm._v("Total")]
                    )
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.leaders.slice(0, _vm.maxleaders), function(
                    leader,
                    index
                  ) {
                    return _c(
                      "tr",
                      {
                        key: index + leader.name,
                        staticClass: "border rounded-lg"
                      },
                      [
                        _c("td", { staticClass: "subtitle1 text-sm-h6" }, [
                          _vm._v(_vm._s(index + 1))
                        ]),
                        _c("td", { staticClass: "subtitle1 text-sm-h6" }, [
                          _vm._v(_vm._s(leader.name))
                        ]),
                        _c(
                          "td",
                          { staticClass: "subtitle1 text-sm-h6" },
                          [
                            _c(
                              "v-chip",
                              {
                                staticClass:
                                  "subtitle1 text-sm-h5 accent2--text font-weight-black",
                                attrs: {
                                  color: "secondary",
                                  label: "",
                                  small: _vm.$vuetify.breakpoint.xs
                                }
                              },
                              [
                                _vm._v(
                                  "+ " +
                                    _vm._s(
                                      leader.details[leader.details.length - 1]
                                        .score
                                    )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("td", { staticClass: "subtitle1 text-sm-h6" }, [
                          _vm._v(_vm._s(leader.totalscore))
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.showotherstats
        ? _c(
            "v-row",
            { staticClass: "d-flex justify-center mt-8", attrs: { dense: "" } },
            [
              _vm.fastest
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "title font-weight-bold text-uppercase secondary--text"
                        },
                        [_vm._v("Fastest Answer")]
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-1 px-6",
                          attrs: {
                            dark: "",
                            color: _vm.fastest.color,
                            large: "",
                            label: ""
                          }
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "white primary--text",
                              attrs: { left: "" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.fastest.lastselected.elapsedtime) +
                                  " "
                              )
                            ]
                          ),
                          _vm._v(" " + _vm._s(_vm.fastest.name) + " ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.streak
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "title font-weight-bold text-uppercase secondary--text"
                        },
                        [_vm._v("Unbroken streak")]
                      ),
                      _vm._l(_vm.streak, function(participant) {
                        return _c(
                          "v-chip",
                          {
                            key: participant.participantsessionid,
                            staticClass: "ma-1 px-6",
                            attrs: {
                              dark: "",
                              large: "",
                              color: participant.color,
                              label: ""
                            }
                          },
                          [
                            _c(
                              "v-avatar",
                              {
                                staticClass: "primary secondary--text",
                                attrs: { left: "" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(participant.lastselected.streak) +
                                    " "
                                )
                              ]
                            ),
                            _vm._v(" " + _vm._s(participant.name) + " ")
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }