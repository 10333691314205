<template>
  <v-container
    class="background question-card d-flex justify-start align-start ma-0 pa-2 pa-sm-4 pa-xl-8 mx-auto"
    :fluid="$vuetify.breakpoint.xs"
    fill-height
  >
    <PresenterTimer :seconds="time"></PresenterTimer>
    <GameQuestion v-if="!gameSession.showleader">
      <template v-slot:game-question-image v-if="!gameSession.showanswer && !gameSession.showleader">
        <GameQuestionImage :image="question.imagelink"> </GameQuestionImage>
      </template>
    </GameQuestion>
    <PresenterAnswerList v-if="!gameSession.showanswer && !gameSession.showleader"> </PresenterAnswerList>
    <PresenterRightAnswer v-if="gameSession.showanswer && !gameSession.showleader">
      <template v-slot:answer-stats>
        <GameAnswerStats></GameAnswerStats>
      </template>
    </PresenterRightAnswer>
    <PresenterLeaderBoard v-if="gameSession.showleader"></PresenterLeaderBoard>
    <PresenterActionBar :devmode="devmode"></PresenterActionBar>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import PresenterTimer from "@/components/game/presenter/PresenterTimer"
import GameQuestion from "@/components/game/common/GameQuestion"
import GameQuestionImage from "@/components/game/common/GameQuestionImage"
import PresenterAnswerList from "@/components/game/presenter/PresenterAnswerList"
import PresenterRightAnswer from "@/components/game/presenter/PresenterRightAnswer"
import GameAnswerStats from "@/components/game/common/GameAnswerStats"
import PresenterLeaderBoard from "@/components/game/presenter/PresenterLeaderBoard"
import PresenterActionBar from "@/components/game/presenter/PresenterActionBar"

export default {
  name: "PresenterQuestion",
  props: {
    time: {
      default: () => 0,
      type: Number,
    },
    devmode: {
      default: () => false,
      type: Boolean,
    },
  },
  components: {
    PresenterTimer,
    GameQuestion,
    GameQuestionImage,
    PresenterAnswerList,
    PresenterRightAnswer,
    GameAnswerStats,
    PresenterLeaderBoard,
    PresenterActionBar,
  },
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
    ...mapState({
      gameSession: (state) => state.game.gameSession,
    }),
  },
}
</script>

<style lang="scss" scoped>
.question-card {
  min-height: 100vh;
  background-image: url("~@/assets/background.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
}
</style>
