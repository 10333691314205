var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass:
        "background question-card d-flex justify-start align-start ma-0 pa-2 pa-sm-4 pa-xl-8 mx-auto",
      attrs: { fluid: _vm.$vuetify.breakpoint.xs, "fill-height": "" }
    },
    [
      _c("PresenterTimer", { attrs: { seconds: _vm.time } }),
      !_vm.gameSession.showleader
        ? _c("GameQuestion", {
            scopedSlots: _vm._u(
              [
                !_vm.gameSession.showanswer && !_vm.gameSession.showleader
                  ? {
                      key: "game-question-image",
                      fn: function() {
                        return [
                          _c("GameQuestionImage", {
                            attrs: { image: _vm.question.imagelink }
                          })
                        ]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          })
        : _vm._e(),
      !_vm.gameSession.showanswer && !_vm.gameSession.showleader
        ? _c("PresenterAnswerList")
        : _vm._e(),
      _vm.gameSession.showanswer && !_vm.gameSession.showleader
        ? _c("PresenterRightAnswer", {
            scopedSlots: _vm._u(
              [
                {
                  key: "answer-stats",
                  fn: function() {
                    return [_c("GameAnswerStats")]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              429969707
            )
          })
        : _vm._e(),
      _vm.gameSession.showleader ? _c("PresenterLeaderBoard") : _vm._e(),
      _c("PresenterActionBar", { attrs: { devmode: _vm.devmode } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }