var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "d-flex justify-center align-self-end" },
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", large: "", color: "secondary" },
              on: { click: _vm.endquiz }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "stop-circle", pull: "left" }
              }),
              _vm._v(" End Quiz")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                outlined: "",
                color: "secondary",
                large: "",
                disabled: !_vm.gameSession.showleader && !_vm.devmode
              },
              on: { click: _vm.nextquestion }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "arrow-right", pull: "left" }
              }),
              _vm._v(" Next Question")
            ],
            1
          )
        ],
        1
      ),
      _vm.devmode
        ? _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "secondary", large: "" },
                  on: { click: _vm.showanswer }
                },
                [_vm._v(" Show Answer")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                outlined: "",
                large: "",
                color: "secondary",
                disabled: !_vm.gameSession.showanswer && !_vm.devmode
              },
              on: { click: _vm.showleaderboard }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "star-half-alt", pull: "left" }
              }),
              _vm._v(" Leaderboard")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }